




































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import Dialog from '@/components/modals/Dialog.vue'
import FilesList from '@/components/FilesList.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Tag from '@/components/_uikit/Tag.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import { SelfResource } from '@/store/types'
import AuthModule from '@/store/modules/auth'
import { DocsEnum } from '@/store/types/docs'
import { AgreementsTypeEnum } from '@/store/types/schema'

@Component({
  components: {
    Dialog,
    FilesList,
    ModalWrapper,
    Tag,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class DocsAgreementModal extends Mixins(NotifyMixin) {
  @Prop({ default: false })
  private visible!: boolean

  @Prop()
  private userData!: SelfResource

  private docsLink = {
    offerEytutis: DocsEnum.OFFER_EYTUTIS,
    offerMalyshev: DocsEnum.OFFER_MALYSHEV,
    offerRogovenko: DocsEnum.OFFER_ROGOVENKO,
    offerSergeeva: DocsEnum.OFFER_SERGEEVA,
    offerSharafieva: DocsEnum.OFFER_SHARAFIEVA,
    offerShvetsov: DocsEnum.OFFER_SHVETSOV,
    offerStrelkova: DocsEnum.OFFER_STRELKOVA,
    offerVolvaka: DocsEnum.OFFER_VOLVAKA,
    personal: DocsEnum.PERSONAL,
    policy: DocsEnum.POLICY,
  }

  private form = {
    agreementConfidential: false,
    agreementPersonal: false,
  }

  private handleConfirm () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          const payload: AgreementsTypeEnum[] = []
          if (this.form.agreementPersonal) {
            payload.push(AgreementsTypeEnum.PROCESSING_OF_PERSONAL_DATA)
          }

          if (this.form.agreementConfidential) {
            payload.push(AgreementsTypeEnum.CONFIDENTIALITY_OF_PERSONAL_DATA)
          }
          await AuthModule.agreement({ agreements: payload })
            .then(() => {
              this.$emit('close')
            })
            .catch(err => this.notifyError(err))
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }
}
